<template>
  <div class="main-box">

    <van-sticky>
      <van-nav-bar title="提醒事项"
                   left-text="返回"
                   right-text="添加"
                   left-arrow
                   @click-left="onClickLeft"
                   @click-right="onClickRight">

      </van-nav-bar>
    </van-sticky>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">

      <van-list v-model:loading="loading" :finished="finished"
                finished-text="没有更多了" @load="onLoad">
        <van-swipe-cell v-for="(item,index) in tableData" :key="index">
          <van-cell :title="getItemTitle(item)"
                    :value="getItemState(item)"
                    center
                    is-link :label="getItemLabel(item)"
                    :to="{'name':'remindEdit','params':{'remindItem':JSON.stringify(item)}}">
          </van-cell>
          <template #right>
            <van-button square
                        :text="item.delFlag==0?'删除':'恢复'"
                        :type="item.delFlag==0?'danger':'primary'" class="delete-button" @click="onClickButton(item)"/>
          </template>
          <template #left>
            <van-button square
                        text="物理删除" type="danger" class="delete-button" @click="onClickDelete(item)"/>
          </template>
        </van-swipe-cell>


      </van-list>
    </van-pull-refresh>
  </div>

</template>

<script>

import {delFlagRemind, delRemind, pageRemind} from '@/requests/remindItem';
import {Dialog, Toast} from 'vant';



export default {
  'name': 'RemindListView',
  data() {
    return {
      'loading': false,
      'refreshing': false,
      'finished': false,
      'rules': {
        'name': [{'required': true, 'message': '请输入提醒名字', 'trigger': 'blur'}],
        'email': [{'required': true, 'type': 'email', 'message': '请输入提醒邮箱', 'trigger': 'blur'}],
        'remindTime': [{'required': true, 'message': '请输入提醒时间', 'trigger': 'blur'}],
        'remindType': [{'required': true, 'message': '请输入提醒类型', 'trigger': 'blur'}],
        'intervalTime': [{'required': true, 'message': '请输入间隔时间', 'trigger': 'blur'}],
        'state': [{'required': true, 'message': '请输入提醒状态', 'trigger': 'blur'}]
      },

      'currentIndex': 0,
      'dialogTitle': '',
      'formItem': {},
      'editVisible': false,
      'isCreate': true,
      'startX': null,
      'startY': null,
      'currentPage': 1,
      'pageSize': 20,
      'totalPage': 10,
      'remindType': [],
      'remindState': [],

      'tableData': [/*
        {
          'name': 'ceshi1',
          'remindTime': '2022-02-02 14:00:31',
          'state': '正常'
        },
        {
          'name': 'ceshi2',
          'remindTime': '2022-02-02 14:00:31',
          'state': '正常'
        },
        {
          'name': 'ceshi3',
          'remindTime': '2022-02-02 14:00:31',
          'state': '正常'
        }*/
      ]

    };
  },
  mounted() {
    this.currentPage = 0;

  },
  'methods': {
    onRefresh() {
      this.currentPage = 1;
      this.finished = false;
      this.getTableData(0);
    },
    onChange() {
      this.getTableData(2);
    },
    onLoad() {
      this.currentPage = this.currentPage + 1;
      this.getTableData(1);
    },
    onClickButton(item) {
      let delFlag = 0;
      if (item.delFlag == 0) {
        delFlag = 1;
      }
      delFlagRemind(item.id, delFlag).then(res => {
        if (res.code === 200) {
          this.onChange();
          Toast.success('成功');
        } else {
          Toast.fail(res.message);
        }
      });

    },
    onClickDelete(item) {
      return new Promise(() => {
        Dialog.confirm({'title': '确定删除吗？'})
          .then(confirm => {
            if (confirm === 'confirm') {
              //删除
              delRemind(item.id).then(res => {
                if (res.code === 200) {
                  this.onChange();
                  Toast.success('成功');
                } else {
                  Toast.fail(res.message);
                }
              });
            }
          })
          .catch(() => {
          });
      });
    },
    //列表结果处理
    pushData(data, type) {
      if (type == 0) {
        this.tableData = data.records;
      } else if (type == 1) {
        this.tableData.push(...data.records);
      } else if (type == 2) {
        let start = (data.current - 1) * data.size,
          resData = [];
        for (let i = 0; i < start; i++) {
          resData.push(this.tableData[i]);
        }
        resData.push(...data.records);
        this.tableData = resData;
      }
      if (this.currentPage >data.pages){
        this.currentPage=data.pages;
      }
      //this.currentPage = data.current;
      this.totalPage = data.pages;

      if (data.current >= this.totalPage) {
        this.finished = true;
      }

      this.loading = false;
      this.refreshing = false;

    },
    getTableData(type) {
      pageRemind(this.currentPage, this.pageSize).then(res => {
        if (res.code === 200) {
          let data = res.data;
          this.pushData(data, type);
        } else {
          Toast.fail(res.message);
        }
      }).catch(e => {
        Toast.fail(e.message);
      });

    },
    onClickLeft() {
      this.$router.push('/main');
    },
    onClickRight() {
      this.$router.push('/remindAdd');
    },

    getItemLabel(item) {
      return item.remindType + ':' + item.remindTime;
    },
    getItemState(item) {
      return item.delFlag == 1 ? '已删除' : item.state;
    },
    getItemTitle(item) {

      return item.name;
    }
  },
  'computed': {
    'user': {
      get() {
        return this.$store.state.user;
      },
      set(val) {
        this.$store.commit('setUser', val);
      }
    }
  }
}
;
</script>

<style scoped lang="scss">

.main-box {
  background-color: #f1f0f0;
  padding: 0 0 0 0;

  .van-pull-refresh {

  }
}

.van-list {
  padding-top: 10px;

  .van-cell {
    text-align: left;
  }

  .delete-button {
    height: 100%;
  }
}

</style>
