import {createRouter, createWebHashHistory} from 'vue-router';

import LoginView from '@/view/LoginView';
import RemindView from '@/view/remind/RemindView';
import MainView from '@/view/MainView';
import store from '@/store';
import RemindListView from '@/view/remind/RemindListView';
import RemindAddView from '@/view/remind/RemindAddView';
import RemindEditView from '@/view/remind/RemindEditView';
import BillFileView from '@/view/bill/BillFileView';
import BillAnalysisView from '@/view/bill/BillAnalysisView';
import BillInfoView from '@/view/bill/BillInfoView';


const router = createRouter({

  'history': createWebHashHistory(),
  'routes': [
    {
      'path': '/',
      'component': LoginView
    },
    {
      'name':'login',
      'path': '/login',
      'component': LoginView
    }, {
      'name':'main',
      'path': '/main',
      'component': MainView
    },
    {
      'name':'remindList',
      'path':'/remindList',
      'component':RemindListView

    },
    {
      'name':'remindAdd',
      'path':'/remindAdd',
      'component':RemindAddView
    },
    {
      'name':'remindEdit',
      'path':'/remindEdit',
      'component':RemindEditView
    },
    {
      'path': '/remind',
      'component': RemindView
    },{
      'name' : 'billFile',
      'path':'/billFile',
      'component':BillFileView
    },{
      'name':'billAnalysis',
      'path':'/billAnalysis',
      'component':BillAnalysisView,
      'meta':{
        'keepAlive': true
      }
    },{
      'name':'billInfo',
      'path':'/billInfo',
      'component':BillInfoView
    }
  ]
});
/**
 * @desc 全局监听路由变化
 */
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    if (!store.state.user) {
      next('/login');
    } else {
      next('/main');
    }
  } else {
    next();
  }
});
// 5.导出路由
export default router;
