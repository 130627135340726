<template>
  <div class="main-box">

    <van-sticky>
      <van-nav-bar title="对账文件"
                   left-text="返回"
                   left-arrow
                   @click-left="onClickLeft">
      </van-nav-bar>

    </van-sticky>

    <van-row>
      <van-col span="24">
        <van-divider class="dates-title" content-position="left">微信</van-divider>
      </van-col>
    </van-row>

    <van-row v-for="(item,index) in wxDates" :key="index">
      <van-col span="2"/>
      <van-col span="22">
        <p class="dates-value">{{ item.start }} ~ {{ item.end }}</p>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="24">
        <van-divider class="dates-title" content-position="left">支付宝</van-divider>
      </van-col>
    </van-row>

    <van-row v-for="(item,index) in aliDates" :key="index">
      <van-col span="2"/>
      <van-col span="22">
        <p class="dates-value">{{ item.start }} ~ {{ item.end }}</p>
      </van-col>
    </van-row>


    <van-row>
      <van-col span="24">
        <van-divider class="upload-title" content-position="left">上传文件</van-divider>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="1"/>
      <van-col span="8">
        <van-uploader :before-read="beforeRead" :after-read="afterRead" accept=".zip,.csv">
          <van-button icon="plus" type="primary">上传文件</van-button>
        </van-uploader>
      </van-col>
      <van-col span="15">
        <van-cell-group inset>
          <van-field name="解压密码" v-model="password" placeholder="请输入解压密码"></van-field>
        </van-cell-group>

      </van-col>
    </van-row>


  </div>

</template>

<script>


import {billDates, uploadBill} from '@/requests/billFile';
import {Toast} from 'vant';

export default {
  'name': 'BillFileView',
  data() {
    return {
      'wxDates': [],
      'aliDates': [],
      'password': ''
    };
  },
  mounted() {
    billDates().then(res => {
      if (res.code == 200) {
        this.wxDates = res.data['微信'];
        this.aliDates = res.data['支付宝'];
      } else {
        Toast.fail(res.message);
      }
    });
  },
  'methods': {
    onClickLeft() {
      this.$router.push('/main');
    },
    afterRead(info) {
      uploadBill(this.password, info.file).then(res => {
        if (res.code == 200) {
          Toast.success('成功');
        } else {
          Toast.fail(res.message);

        }
      });
    },
    beforeRead() {
      if (!this.password || this.password == '') {
        Toast.fail('请输入解压密码');
        return false;
      }
      return true;
    }
  },
  'computed': {}
}
;
</script>

<style scoped lang="scss">

.main-box {
  background-color: #f1f0f0;
  padding: 0 0 0 0;

}

.dates-title {
  font-weight: bold;
  font-size: 16px;
  color: #1989fa;
  border-color: #1989fa;
  padding: 0 16px;

}

.upload-title {
  font-weight: bold;
  font-size: 16px;
  color: rgba(28, 46, 59, 0.88);
  border-color: rgba(28, 46, 59, 0.88);
  padding: 0 16px;

}

.dates-value {

  text-align: left;
  color: rgba(27, 41, 47, 0.90);
  font-weight: 200;
  font-size: 14px;
  line-height: 14px;

}

</style>
