<template>
  <div class="common-layout">
    <van-nav-bar title="首页" />
    <van-grid class="grid" :gutter="10" :clickable="true" square :column-num="2">
      <van-grid-item icon="photo-o" text="广告位" />
      <van-grid-item icon="description" text="对账文件" to="/billFile"/>

      <van-grid-item icon="todo-list-o" text="提醒事项" to="/remindList"/>
      <van-grid-item icon="column" text="账单" to="/billAnalysis"/>
    </van-grid>
  </div>
</template>

<script>
export default {
  'name': 'MainView',
  data() {
    return {
    };
  }, mounted() {

  }, 'methods': {}, 'computed': {}
};
</script>

<style scoped lang="scss">

.common-layout {
  height: 100%;
  width: 100%;

  .grid{
    height: 100%;
  }
}

</style>
