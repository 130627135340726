import axios from '@/requests';

const headers = {
  'Content-Type': 'multipart/form-data'
};

function billDates() {
  return axios.post('/bill/dates');
}

function uploadBill(password, file) {
  const formData = new FormData();
  formData.append('password', password);
  formData.append('file', file);
  return axios.post('/bill/upload', formData, headers);
}


export {
  billDates,
  uploadBill
};
